import React from 'react';

import Routes from './routes';
import { GlobalStyle } from './style';

const App = () => (
  <>
    <Routes />
    <GlobalStyle />
  </>
);

export default App;
