import React from 'react';

import logo from '../../../assets/images/logo-zenvia.png';
import {
  Container,
  Line,
  LogoName,
  Image,
} from './styles';

function Logo() {
  return (
    <Container>
      <a href="https://www.zenvia.com/">
      <Image src={logo} alt="Zenvia" />
      </a>
      <Line />
      <LogoName>
        HubSpot App
      </LogoName>
    </Container>
  );
}

export default Logo;
