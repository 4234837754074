import styled from 'styled-components';

export const Content = styled.div`
  margin: 0;
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: linear-gradient(115.6deg, #38265E 0%, #432360 59.58%, #6d4086 100%);

  p, a {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .head {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 250px;
    font-weight: 900;
    letter-spacing: 25px;
    color: #fff;

    span {
      position: relative;
      display: inline-block;
    }
    span:before, span:after{
      position: absolute;
      top:50%;
      width: 50%;
      height: 1px;
      background: #fff;
      content: '';
    }
    span:before{
      left: -55%;
    }
    span:after{
      right: -55%;
    }
  }
`;
