import React from 'react';

import { Container, Grid } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';

import configs from '../../../assets/images/configs.png';

import HeaderMenu from '../../../components/Header';
import Label from '../../../components/Label';
import {
  Content,
  Image,
  Back
} from './style';

const Success = () => {
  return (
    <>
    <HeaderMenu activeTab={1} />
    <Back>
      <Label color='white' size='large' align='center' padding='50px'>
      Aplicativo instalado com sucesso!
      </Label>
    </Back>
    <Content>
      <Container maxWidth='lg'>
        <Grid className='grid' spacing={8} container alignItems="stretch">
          <Grid item xs={12} sm={6} md={6}>
            <Image src={configs} alt='fields'/>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
              <Label size='medium' color='dark' align='right'>
              Aumente os índices de satisfação do cliente com uma comunicação eficiente  
              </Label><br/><br/>
              <Label size='min' color='dark' align='right'>
              Acesse o menu de configurações no menu superior, para inserir as informações
              necessárias conforme a documentação disponível no 
              <a href="https://support.zenvia.com/pt/"> Nosso Suporte </a>.
              </Label>
          </Grid>
        </Grid>

      </Container>
    </Content>
    </>
  );
}

export default Success;
