import { createGlobalStyle } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';

import Roboto from './assets/fonts/Roboto.ttf';

export const GlobalStyle = createGlobalStyle`

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  @font-face {
    font-family: "Roboto";
    src: url(${Roboto});
  }

  body {
    font-family: 'Roboto';

    &::-webkit-scrollbar {
      width: 7px;
      color: gray;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: gray;
    }
  }
`;

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6F2C91',
    },
    secondary: {
      main: '#009BDF',
    },
  },
});
