import React from 'react';

import Label from '../Label';

import {
  Content
} from './style';

const Code = ({ text  }) => {
  return (
    <Content>
      <Label color='red' size='min' align='center' padding='50px'>
      {text}
      </Label>
    </Content>
  );
};

export default Code;
