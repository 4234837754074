import styled from 'styled-components';

export const Back = styled.div`
  background: linear-gradient(115.6deg, #38265E 0%, #432360 59.58%, #6d4086 100%);
  padding: 0 100px;
`

export const Content = styled.div`
  margin: 0;
  display: flex;
  padding: 0 100px;
  flex-direction: column;

  .grid {
    margin: 50px 0;
  }
`;

export const Button = styled.div`
  background-color: #701c9a;
  :hover {
    background-color: #5b1487;
  },
  border-color: transparent;
  border-radius: 8px;
  border: 1px solid transparent;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 600;
  height: 3rem;
  cursor: pointer;
  padding-bottom: 0;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.75em;
`;

export const Image = styled.img`
  float:left;
  width: 500px;
`;
