import React from 'react';

import { Container } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';

import HeaderMenu from '../../components/Header';
import { Content } from './style';

const NotFound = (props) => {
  return (
    <>
    <HeaderMenu activeTab={1} />
    <Content>
      <Container maxWidth='lg'>
        <h1 class="head"><span>404</span></h1>
        <p>Oops! The Page you requested was not found!</p>
        <p>The Page you are looking for doesn't exist or an other error occured.</p>
      </Container>
    </Content>
    </>
  );
}

export default NotFound;
