import styled from 'styled-components';

export const Back = styled.div`
  background: linear-gradient(115.6deg, #38265E 0%, #432360 59.58%, #6d4086 100%);
  padding: 0 100px;
`

export const Content = styled.div`
  margin: 0;
  display: flex;
  padding: 0 100px;
  flex-direction: column;

  .grid {
    margin: 50px 0;
  }
`;

export const Image = styled.img`
  float:left;
  width: 500px;
`;
