import styled from 'styled-components';

const colors = {
    main: '#6F2C91',
    red: '#e94541',
    green: '#0a9e9e',
    dark: '#3f345f',
    blue: '#3139B6',
    black: '#0f0f0f',
    white: '#F6F9FB',
    gray: '#908A8A'
}

const sizes = {
    extra: '76px',
    title: '56px',
    large: '44px',
    big: '32px',
    medium: '24px',
    min: '18px',
    nano: '14px',
    pico: '10px'
}

const aligns = {
    center: 'center',
    left: 'left',
    right: 'right'
}

export const Container = styled.p`
    font-family: 'Roboto';
    line-height: normal;
    font-weight: ${(props) => props.weight ? props.weight:'400'};
    padding: ${(props) => props.padding ? props.padding:'20px 0 20px 0'};
    color: ${(props) => colors[props.color]};
    font-size: ${(props) => sizes[props.size]};
    text-align: ${(props) => aligns[props.align]};
`
