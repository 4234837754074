import React from 'react';
import queryString from 'query-string'

import { Container, Grid } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';

import HeaderMenu from '../../../components/Header';
import Code from '../../../components/Code';
import Label from '../../../components/Label';
import {
  Content,
  Back
} from './style';

const Error = (props) => {
  return (
    <>
    <HeaderMenu activeTab={1} />
    <Back>
      <Label color='red' size='large' align='center' padding='50px'>
      Ocorreu um erro durante a tentativa de instalação do aplicativo.
      </Label>
    </Back>
    <Content>
      <Container maxWidth='lg'>
        <Grid className='grid' spacing={8} container alignItems="stretch">
          <Grid item xs={12} sm={6} md={6}>
            <Code
              text={queryString.parse(props.location.search).message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
              <Label size='min' color='dark' align='right'>
              Tente novamente dentro de alguns minutos ou caso o erro persista, encaminhe
              um e-mail para nosso Suporte (atendimento@zenvia.com), para que nosso time
              possa apoiar na solução do problema.
              </Label>
          </Grid>
        </Grid>
      </Container>
    </Content>
    </>
  );
}

export default Error;
