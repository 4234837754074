import React from 'react';

import { Container, Grid } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';

import HeaderMenu from '../../../components/Header';
import Label from '../../../components/Label';
import {
  Content,
  Back,
  Button
} from './style';

const Installation = () => {
  return (
    <>
      <HeaderMenu activeTab={1} />
      <Back>
        <Label color='white' size='large' align='center' padding='50px'>
          Zenvia - Templates WhatsApp
        </Label>
      </Back>
      <Content>
        <Container maxWidth='md'>
          <Grid className='grid' spacing={12} container alignItems="center" style={{ justifyContent: "center" }}>
            <Grid item>
              <Label size='medium' color='dark' align='center'>
                Envie mensagens de WhatsApp usando os templates criado no Zenvia App diretamente dos seus workflows do HubSpot.
              </Label>
            </Grid>
            <Grid item >
              <Button onClick={ event => window.location.href = '/install' }>
                Conectar HubSpot
              </Button>
            </Grid>

          </Grid>

        </Container>
      </Content>
    </>
  );
};

export default Installation;
